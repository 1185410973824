<!-- eslint-disable -->
<template>
  <div class="page_box">
    <sb-headline :title="'活动数据分析'"></sb-headline>

    <my-table ref="list" :data="listData" :rowKey="'orderNo'" @page="pageHandler" :listLoading="listLoading">
      <template #header="">
        <div class="title">
          <h2>
            {{ activityName||'asdasdas'}}
            <router-link
              class="text-btn-edit look-coupon"
              :to="`/flowActivity/add?activityCode=${activityCode}`"
              >{{ $t("v210801.view-activity") }}</router-link
            >
          </h2>
        </div>
      </template>

      <template #navBar="params">
        <div class="search_box">
          <el-tabs v-model="searchData.categoryCode" @tab-click="handleClick">
            <el-tab-pane label="引流加购" name="102"></el-tab-pane>
            <el-tab-pane label="引流赠送" name="101"></el-tab-pane>
          </el-tabs>
          <el-row :gutter="10">
            <el-col :span="6">
              <div class="search_item">
                <div>手机号：</div>
                <el-input v-model="searchData.mobile" size="small"></el-input>
              </div>
            </el-col>
          </el-row>
          <el-row :gutter="10" style="margin-top: 10px;">
            <el-col :span="4" :offset="20">
              <el-button @click="pageHandler(params, 1, 'reset')">重置</el-button>
              <el-button @click="pageHandler(params, 1)" type="primary">查询</el-button>
            </el-col>
          </el-row>
        </div>
      </template>

      <el-table-column type="expand">
        <template slot-scope="props">
          <el-table :data="props.row.itemList" size="small" border stripe>
            <el-table-column label="商品编号" prop="skuId"/>
            <el-table-column label="商品名称" prop="skuName"/>
            <el-table-column label="商品数量" prop="skuNum"/>
            <el-table-column label="价格" prop="price"/>
            <el-table-column label="商品类型" prop="skuTypeDesc"/>
          </el-table>
        </template>
      </el-table-column>

      <el-table-column label="订单号"  prop="orderNo" :min-width="180">
        <template slot-scope="scope">
          <div style="display: inline-block">{{ scope.row.orderNo || scope.row.orderDetailNo }}</div>
        </template>
      </el-table-column>
      <el-table-column label="关联订单号" prop="associatedNo" :min-width="180"></el-table-column>
      <el-table-column label="用户姓名" prop="packageCode" :min-width="120">
        <template slot-scope="scope">
          {{ scope.row.customerName || '-' }}
        </template>
      </el-table-column>
      <el-table-column label="手机号" prop="mobile" :min-width="100">
        <template slot-scope="scope">
          {{ scope.row.mobile || '-' }}
        </template>
      </el-table-column>

      <el-table-column label="应收金额" prop="paymentPrice" :min-width="150" v-if="this.searchData.categoryCode ==='102'">
        <template slot-scope="scope">
          {{ scope.row.paymentPrice }}
        </template>
      </el-table-column>
      <el-table-column label="实收金额" prop="actualPrice" :min-width="150" v-if="this.searchData.categoryCode ==='102'">
        <template slot-scope="scope">
          {{ scope.row.actualPrice }}
        </template>
      </el-table-column>

      <el-table-column label="优惠金额" prop="discountPrice" :min-width="150" v-if="this.searchData.categoryCode ==='102'">
        <template slot-scope="scope">
          {{ scope.row.discountPrice }}
        </template>
      </el-table-column>

      <el-table-column label="付款状态" prop="paidState" :min-width="150" v-if="this.searchData.categoryCode ==='102'">
        <template slot-scope="scope">
          {{ scope.row.paidState | paidStateFilter}}
        </template>
      </el-table-column>

      <el-table-column label="领取状态" prop="issueState" :min-width="150">
        <template slot-scope="scope">
          {{ scope.row.issueState | issueStateFilter}}
        </template>
      </el-table-column>

      <el-table-column label="操作时间" prop="ctime" :min-width="180">
        <template slot-scope="scope">
          {{ scope.row.ctime || '-' }}
        </template>
      </el-table-column>
      <el-table-column label="发放类型" prop="categoryCodeDesc" :min-width="100">
        <template slot-scope="scope">
          {{ scope.row.categoryCodeDesc || '-' }}
        </template>
      </el-table-column>
      <el-table-column label="收款单号" prop="receiptNo" :min-width="150" v-if="this.searchData.categoryCode ==='102'">
        <template slot-scope="scope">
          {{ scope.row.receiptNo || '-' }}
        </template>
      </el-table-column>
    </my-table>
  </div>
</template>

<script>
/* eslint-disable */
import { Component, Prop, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { emptyToLine, splitThousands } from "@/utils/common";
import { Table } from "@/utils/decorator";
import QRCode from "qrcodejs2";
import dayjs from "dayjs";
import t from "@common/src/i18n/t";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
    data() {
    //这里存放数据
      return {
        activityName: '',             //活动名称
        activityCode: '',             //活动code
        listData: [{},{},{}],
        // 搜索数据
        searchData: {
          mobile: "",           //手机号
          categoryCode: "102",     //发放类型
          // userPhone: ""
        },
        searchKey: "customerName", //下拉input所选Key
        listLoading: false, //列表loading
        channelNameList: [                     //渠道名称list
          { channel: '', linkName: '' ,channelMobile: ''}
        ],
        promotionDialogVisible: false,        //推广信息弹窗
        rowActivityCode: "",                  //当前点击的活动code
        activityPopularizeList: [],           //活动推广列表
        url: "",
        rewardDialogVisible: false,
        commissionConfList: [],
        orderItems: [{
          "item":"createTime",
          "type":"desc"
        }],
      };
    },
  filters: {
    stateFilter: function(val) {
      switch (val) {
        case 0:
          return "未开始";
        case 1:
          return "进行中";
        case 2:
          return "已结束";
        default:
          return "";
      }
    },
    paidStateFilter(val) {
      switch (val) {
        case 1:
          return "已付款";
        case 2:
          return "已退款";
        default:
          return "-";
      }
    },
    issueStateFilter(val){
      switch (val){
        case 1:
          return "已领取";
        case 2:
          return "未领取";
        default:
          return "-";
      }
    }
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    pageHandler(params, num, type) {
      if(type == 'reset'){
        this.resetSearchData();
      }
      this.getActivityList(params, num ? num : params.pageNum, params.pageSize);
    },

    // 跳转活动详情
    gotoDetail(row){
      this.$router.push(`/flowActivity/add?${row.packageCode}`);
    },

    handleClick(tab, event) {
      this.pageNum = 1
      this.searchData.categoryCode = tab.name
      this.$refs.list.init();
    },


    // 重置搜索数据
    resetSearchData(e) {
      this.searchData = {
        mobile: "",           //手机号
      }
    },

    // 获取活动列表
    getActivityList(params,pageNum,pageSize) {
      this.listLoading = true;
      this.$store.dispatch('management/getFlowActivityDataList',{
        ...this.searchData,
        pageNum,
        pageSize,
        activtiyCode: this.$route.query.activityCode
      }).then(res => {
        if(res.code == 0){
          this.listData = res.data.list;
          params.render(res.data.total);
        }
        this.listLoading = false;
      })
    },

  },


  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.activityCode = this.$route.query.activityCode;
    this.activityName = this.$route.query.activityName;
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {

  },
  //生命周期 - 创建之前
  beforeCreate() {},
  //生命周期 - 挂载之前
  beforeMount() {},
  //生命周期 - 更新之前
  beforeUpdate() {},
  //生命周期 - 更新之后
  updated() {},
  //生命周期 - 销毁之前
  beforeDestroy() {},
  //生命周期 - 销毁完成
  destroyed() {},
  //如果页面有keep-alive缓存功能，这个函数会触发
  activated() {},
}
</script>
<style lang="scss" scoped>
  //@import url(); 引入公共css类
  .page_box {
    padding: 0 20px;
    .search_box {
      background: #fff;
      padding: 20px;
      margin-top: 20px;
    }
    .table_nav_bar{
      width: 100%;
      background: #fff;
      padding: 20px 20px 0 20px;
      box-sizing: border-box;
    }
  }
  .handle_btn{
    padding: 0;
  }
  .channel_item{
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    >.el-input:nth-child(1){
      width: 260px;
    }
  }
  .channel_item:last-child{
    margin: 0;
  }
  .popularize_list_qrcode{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .qrcode_img_src{
    position: absolute;
    top: -10000%;
    z-index: -10000;
  }
  .search_item{
    display: flex;
    align-items: center;
    >div:first-child{
      flex-shrink: 0;
    }
  }
  ::v-deep .el-table__header thead tr th:first-of-type, .el-table__body tbody tr td:first-of-type{
    padding: 0 !important;
  }
  ::v-deep .el-table__body tbody tr td:first-of-type {
    padding-left: 0px !important;
  }
</style>
